import styled from 'styled-components';
import Typography from '../constants/typography';
import Colours from '../constants/colours';
import {Button} from '../components/Button';
import {NAVIGATION_COLLAPSE_POINT} from '../constants/layouts';

type PageWrapperProps = {
  grey?: boolean;
};

export const Heading = styled.h1`
  font-size: ${Typography.title.desktop.fontSize};
`;

type ParagraphProps = {
  bold?: boolean;
};

export const Paragraph = styled.p`
  font-size: ${Typography.body.desktop.fontSize};
  font-weight: ${(p: ParagraphProps) => (p.bold ? 'bold' : 'normal')};
  line-height: ${Typography.body.desktop.lineHeight};
`;

export const PageWrapper = styled.section<PageWrapperProps>`
  padding: 4em 0;
  background-color: ${(props) =>
    props.grey ? Colours.grey[100] : Colours.white};
`;

export const PageCentre = styled.div`
  display: grid;
  place-content: center;
`;

export const Spacer = styled.div`
  height: 1rem;
`;

export const RichText = styled.div`
  font-size: ${Typography.body.desktop.fontSize};
  line-height: ${Typography.body.desktop.lineHeight};
  color: ${Colours.black};

  p {
    margin: 1em 0;
  }

  img.page-image {
    max-width: 100%;
    margin-top: 1em;
  }

  a.link-button {
    display: inline-block;
    color: ${Colours.black};
    border: 1px solid ${Colours.black};
    padding: 8px 24px;
    transition: background-color 0.3s;
    margin-top: 0.5em;
    font-size: 1em;
  }

  a.link-button:hover {
    background-color: ${Colours.black};
    color: ${Colours.white};
  }

  a.link-button > div {
    transform: translateY(0.1em);
  }

  blockquote {
    color: ${Colours.grey[500]};
    font-size: 1.1rem;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colours.white};
  padding: 3rem;
  font-size: 1.2rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.065);
  border-radius: 3px;

  @media (max-width: ${NAVIGATION_COLLAPSE_POINT}) {
    padding: 1.5rem;
  }
`;

export const Skeleton = styled.div`
  background-color: ${Colours.grey[400]};
  width: 200px;
  height: 1rem;
  border-radius: 2px;
  animation: pulse 1s infinite alternate;

  @keyframes pulse {
    0% {
      background-color: ${Colours.grey[100]};
    }

    100% {
      background-color: ${Colours.grey[300]};
    }
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 0;
  line-height: 1.4;
`;

export const FormTitle = styled.label`
  display: block;
  font-size: ${Typography.subtitle.desktop.fontSize};
  font-weight: bold;
  line-height: 2rem;
  color: ${Colours.grey[700]};
`;

export interface LabelProps {
  error: boolean;
}

export const Label = styled.label`
  display: block;
  font-size: 1.1rem;
  color: ${(p: LabelProps) => (p.error ? Colours.red : Colours.grey[700])};
`;

export const TextArea = styled.textarea`
  box-sizing: border-box;
  border: 1px solid ${Colours.grey[300]};
  font-size: 1.2rem;
  font-family: 'TT Commons', 'Helvetica Neue', helvetica, arial, sans-serif;
  padding: 0.8rem;
  color: ${Colours.grey[800]};
  outline: none;
  width: 100%;

  &:focus {
    border: 1px solid ${Colours.green};
  }
`;

export const ButtonSpacing = styled(Button)`
  margin-top: 1rem;
`;

export const Separator = styled.div`
  border-top: 2px solid ${Colours.grey[300]};
  height: 2px;
`;

export const PageHeader = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

export const PageDescription = styled(Paragraph)`
  padding: 2rem;
  padding-top: 0.8rem;
  color: ${Colours.grey[600]};
`;
