import React, {ReactElement, ReactNode} from 'react';
import styled from 'styled-components';
import Img, {FluidObject} from 'gatsby-image';
import Colours from '../constants/colours';
import Typography from '../constants/typography';
import {NAVIGATION_COLLAPSE_POINT} from '../constants/layouts';

const HeaderImage = (props: {src: FluidObject; children?: ReactElement}) => (
  <ImageWrapper data-test-id="header-image">
    {props.src ? <Img fluid={props.src} /> : ''}
    <ContentWrapper>{props.children}</ContentWrapper>
  </ImageWrapper>
);

const ImageWrapper = styled.div`
  position: relative;
  background-color: #eee;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${Colours.white};
  font-size: ${Typography.heading.desktop.fontSize};

  @media (max-width: ${NAVIGATION_COLLAPSE_POINT}) {
    font-size: ${Typography.heading.mobile.fontSize};
  }
`;

export const Caption = (props: CaptionProps) => (
  <CaptionWrapper>
    <CaptionContent {...props} data-test-id="caption">
      {props.caption}
    </CaptionContent>
  </CaptionWrapper>
);

interface CaptionProps extends CaptionContentProps {
  caption: ReactNode;
}

interface CaptionContentProps {
  narrow: boolean;
  wide?: boolean;
}

const CaptionContent = styled.div<CaptionContentProps>`
  font-size: 1.2em;
  line-height: 1.5;
  color: #333;
  width: 95%;
  max-width: ${(p) => (p.narrow ? '700px' : p.wide ? '1400px' : '1000px')};
  margin: auto;

  @media (max-width: 1000px) {
    width: ${(p) => (p.narrow ? '75%' : p.wide ? '95%' : '80%')};
  }

  @media (max-width: 500px) {
    width: ${(p) => (p.narrow ? '75%' : p.wide ? '100%' : '85%')};
  }
`;

const CaptionWrapper = styled.div`
  padding: 1.5em;
  background: #f7f7f7;
`;

export default HeaderImage;
